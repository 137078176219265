import jQuery from 'jquery';
import 'slick-carousel';
// import 'slick-animation';
// import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger.js';
import ScrollMagic from 'scrollmagic';
import videojs from 'video.js';
import Inputmask from 'inputmask';
import 'select2';
import ClipboardJS from 'clipboard';
import Mark from 'mark.js';
import { CountUp } from 'countup.js';
// import './map';

jQuery(document).ready(function() {
    // Clipboard
    initClipboard();

    // Highlight search text
    highlightSearchText();

    // Alarm about slider
    initAlarmAboutSlider();

    // Alarm form tabs
    initAlarmFormTabs();

    // Alarm plans slider
    initAlarmPlansSlider();

    // About dev slider
    initAboutDevSlider();

    // About history slider
    initAboutHistorySlider();

    // Scroll top
    scrollTop();

    // Add guard type value to contact forms
    addGuardTypeToContactForms();

    // Licens popup
    function popupToggle() {
        const body = document.querySelector('body');

        body.classList.contains('popup-licens--open')
            ? body.classList.remove('popup-licens--open')
            : body.classList.add('popup-licens--open');
    }

    function setPopupImg() {
        const licensesSectionList = document.querySelector(
                '.licenses-section-list'
            ),
            overflow = document.querySelector('.licens-popup-overflow'),
            licensPopupClose = document.querySelector('.licens-popup__close'),
            body = document.querySelector('body'),
            licensPopupList = document.querySelector('.licens-popup-list');

        if (licensesSectionList) {
            licensesSectionList.addEventListener('click', (e) => {
                e.preventDefault();

                if (e.target.closest('.list-item__link')) {
                    let dataUrls = e.target
                            .closest('.list-item__link')
                            .getAttribute('data-image-url'),
                        data = '';

                    dataUrls = dataUrls.split(',');

                    dataUrls.forEach((item) => {
                        let img = `<img src="${item}" alt="" class="licens-popup__img">`;

                        data += img;
                    });

                    licensPopupList.innerHTML = data;

                    popupToggle();
                }
            });
        }

        document.addEventListener('keydown', (e) => {
            if (body.classList.contains('popup-licens--open')) {
                e.key === 'Escape' ? popupToggle() : '';
            }
        });

        if (overflow) {
            overflow.addEventListener('click', () => popupToggle());
        }

        if (licensPopupClose) {
            licensPopupClose.addEventListener('click', () => popupToggle());
        }
    }

    setPopupImg();
    // Licens popup --- End

    jQuery('.service-slider__item').each(function(i) {
        let number = i + 1;
        if (i < 9) {
            jQuery(this)
                .find('.service-slider__info-count')
                .html('0' + number);
        } else {
            jQuery(this)
                .find('.service-slider__info-count')
                .html(number);
        }
    });

    // gsap.registerPlugin(ScrollTrigger);
    // let headerHeight = jQuery('.header').height();

    jQuery('select').select2({
        minimumResultsForSearch: -1,
    });

    jQuery('#manager-region').on('change', function(e) {
        $(this)
            .closest('form')
            .trigger('submit');
    });

    new Inputmask('+380 99-999-99-99').mask(jQuery('.phone'));

    // anchors scroll animate

    jQuery(document).on('click', '.anchor', function(event) {
        if (jQuery.attr(this, 'href').indexOf('#') !== 0) return;

        event.preventDefault();

        var windowHeight = jQuery(window).height();
        var targetHeight = jQuery(jQuery.attr(this, 'href')).height();
        var offset =
            windowHeight > targetHeight
                ? Math.round((windowHeight - targetHeight) / 2)
                : headerHeight + 20;

        jQuery('html, body').animate(
            {
                scrollTop:
                    jQuery(jQuery.attr(this, 'href')).offset().top - offset,
            },
            1200
        );
    });

    // scroll animations

    let controller = new ScrollMagic.Controller();
    jQuery('.animating').each(function() {
        new ScrollMagic.Scene({
            triggerElement: this,
            reverse: true,
            triggerHook: 'onEnter',
            offset: 50,
        })
            .setClassToggle(this, 'is-inview')
            .addTo(controller)
            .on('enter', function(event) {
                startCounterAnimation(event.target.triggerElement());
            });
    });

    // init sliders

    initLicensesSlider();
    initAdvantagesSlider();
    initServicesSlider();
    intOfferSlider();
    initServiceSlider();

    jQuery('.main-slider').on('init', function() {
        jQuery('.top-screen').removeClass('hidden');
    });

    jQuery('.main-slider')
        .slick({
            autoplay: false,
            autoplaySpeed: 3000,
            dots: true,
            arrows: false,
            fade: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            appendDots: '.main-slider-dots__inner',
        })
        .slickAnimation();

    jQuery('.connection-slider').slick({
        speed: 4000,
        autoplay: true,
        autoplaySpeed: 0,
        centerMode: true,
        cssEase: 'linear',
        pauseOnHover: false,
        slidesToShow: 4,
        slidesToScroll: 2,
        variableWidth: true,
        infinite: true,
        initialSlide: 1,
        arrows: false,
        buttons: false,
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    rows: 2,
                },
            },
        ],
    });

    jQuery('.reviews-slider').slick({
        dots: false,
        arrows: true,
        fade: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: jQuery('.reviews__arrows .slider-next'),
        prevArrow: jQuery('.reviews__arrows .slider-prev'),
        responsive: [
            {
                breakpoint: 681,
                settings: {
                    dots: true,
                    arrows: false,
                },
            },
        ],
    });

    jQuery('.security-info__for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        pauseOnHover: false,
        fade: true,
        asNavFor: '.security-info__nav',
    });

    jQuery('.security-info__nav').slick({
        slidesToShow: 5,
        vertical: true,
        slidesToScroll: 1,
        asNavFor: '.security-info__for',
        dots: true,
        centerMode: true,
        focusOnSelect: true,
    });

    jQuery('.security-info__nav-item').on('click', function() {
        jQuery('.security-info__for').slick('slickPause');
    });

    jQuery('.special-offers').slick({
        dots: true,
        arrows: true,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        nextArrow: jQuery('.special-offers-arrows .slider-next'),
        prevArrow: jQuery('.special-offers-arrows .slider-prev'),
        responsive: [
            {
                breakpoint: 1201,
                settings: {
                    dots: false,
                },
            },
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    slidesToShow: 1,
                    dots: true,
                },
            },
        ],
    });

    jQuery('.smart-house-slider').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
        arrows: true,
        buttons: false,
        fade: true,
        nextArrow: jQuery('.smart-house__controls .slider-next'),
        prevArrow: jQuery('.smart-house__controls .slider-prev'),
    });

    jQuery('.special-offer-slider').slick({
        speed: 600,
        autoplay: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        initialSlide: 1,
        dots: true,
        arrows: true,
        appendDots: '.main-slider-dots__inner',
        prevArrow: $('.offer-slider__arrow_prev'),
        nextArrow: $('.offer-slider__arrow_next'),
    });

    // toggle header menu

    const headerInfo = document.querySelector('.header-info');
    const headerHamburger = document.querySelector('.header-hamburger');
    const body = document.querySelector('body');

    // let tlHeaderInfo = gsap.timeline({ paused: true, reversed: true });

    tlHeaderInfo.fromTo(
        headerInfo,
        0.5,
        { opacity: 0, display: 'none', y: -15, ease: 'ease-in-out' },
        { opacity: 1, display: 'flex', y: 0 }
    );
    tlHeaderInfo.reverse();

    headerHamburger.addEventListener('click', () => {
        tlHeaderInfo.reversed(!tlHeaderInfo.reversed());
        headerHamburger.classList.toggle('header-hamburger-opened');
        body.classList.toggle('header-info-visible');
    });

    // toggle search panel menu

    const searchPanel = document.querySelector('.search-panel');
    const headerSearch = document.querySelector('.header-search');
    const closeBtn = document.querySelector('.search-panel__close');

    // let tlSearchPanel = gsap.timeline({ paused: true });

    tlSearchPanel.fromTo(
        searchPanel,
        0.5,
        { opacity: 0, display: 'none', y: -15, ease: 'ease-in-out' },
        { opacity: 1, display: 'flex', y: 0 }
    );
    tlSearchPanel.reverse();

    headerSearch.addEventListener('click', () => {
        toggleSearch();
    });
    closeBtn.addEventListener('click', () => {
        toggleSearch();
    });
    function toggleSearch() {
        tlSearchPanel.reversed(!tlSearchPanel.reversed());
        headerSearch.classList.toggle('search-panel-opened');
        body.classList.toggle('search-panel-visible');
    }
    jQuery(document).mouseup(function(e) {
        let evntTarget = jQuery(e.target);
        if (
            evntTarget.closest(searchPanel).length == 0 &&
            evntTarget.closest(headerSearch).length == 0
        ) {
            tlSearchPanel.reversed(!tlHeaderInfo.reversed());
            headerSearch.classList.remove('search-panel-opened');
            body.classList.remove('search-panel-visible');
            tlSearchPanel.reverse();
        }
        if (
            evntTarget.closest(headerInfo).length == 0 &&
            evntTarget.closest(headerHamburger).length == 0
        ) {
            tlHeaderInfo.reversed(!tlHeaderInfo.reversed());
            headerHamburger.classList.remove('header-hamburger-opened');
            body.classList.remove('header-info-visible');
            tlHeaderInfo.reverse();
        }
    });

    // add dropdown icon

    jQuery('.menu li').each(function(i, el) {
        if (jQuery(el).children().length > 1) {
            jQuery(el).addClass('parent-menu');
            jQuery(el)
                .children('a')
                .append(
                    '<a href="#" class="icon-dropdown"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.9998 15.0007L7.75684 10.7577L9.17184 9.34375L11.9998 12.1727L14.8278 9.34375L16.2428 10.7577L11.9998 15.0007Z" fill="#757D85"/> </svg>\n</a>'
                );
        }
    });

    // change play button

    let videoBtmCustom = jQuery('.j-video__btn');
    let videoBtmDefault = jQuery('.vjs-big-play-button');
    videoBtmCustom.appendTo(videoBtmDefault);

    // toggle about content

    jQuery('.general-about .arrow-link').on('click', function(e) {
        e.preventDefault();
        jQuery(this)
            .closest('.general-about')
            .toggleClass('general-about-visible');
    });

    jQuery('.payment-section .expand-link').on('click', function(e) {
        e.preventDefault();
        jQuery(this)
            .closest('.payment__col')
            .find('.payment__list')
            .toggleClass('payment-list-visible');
        jQuery(this).toggleClass('text-visible');
    });

    // console.log(jQuery('.payment__list').height())
    // location tabs

    jQuery('.location__menu-item').on('click', function() {
        jQuery('.location__menu-item')
            .removeClass('active')
            .eq(jQuery(this).index())
            .addClass('active');
        jQuery('.location__item')
            .hide()
            .eq(jQuery(this).index())
            .fadeIn();
    });

    jQuery('.location__menu-item.active').trigger('click'); // init map

    // faq accordion

    jQuery('.faq__header').on('click', function() {
        if (!jQuery(this).hasClass('active')) {
            jQuery('.faq__description').slideUp();
            jQuery('.faq__header').removeClass('active');
        }
        jQuery(this)
            .toggleClass('active')
            .next()
            .slideToggle();
    });

    jQuery(window)
        .on('resize scroll update', function() {
            let headerTopHeight = jQuery('.header-top').height() + 7;
            if (jQuery(window).scrollTop() > headerTopHeight) {
                jQuery('.header').addClass('fixed');
            } else {
                jQuery('.header').removeClass('fixed');
            }
        })
        .trigger('update');

    jQuery('.icon-dropdown').on('click', function(e) {
        if (jQuery(window).width() < 1271) {
            e.preventDefault();
            jQuery(this)
                .closest('.menu__item')
                .find('.sub-menu')
                .slideToggle();
            jQuery(this)
                .closest('.menu__item')
                .toggleClass('dropdown-active');
        }
    });
    jQuery('.info-menu__icon').on('click', function() {
        if (jQuery(window).width() < 769) {
            jQuery(this)
                .closest('.header-info__col')
                .find('.info-content')
                .slideToggle();
            jQuery(this)
                .closest('.header-info__col')
                .toggleClass('active');

            jQuery(this)
                .closest('.footer-info__col')
                .find('.info-content')
                .slideToggle();
            jQuery(this)
                .closest('.footer-info__col')
                .toggleClass('active');
        }
    });

    if (
        !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
            navigator.userAgent
        )
    ) {
        jQuery('body').addClass('is-desktop');
    } else {
        jQuery('body').addClass('is-device');
    }

    if (jQuery('.top-info-section .offer-slider').length) {
        jQuery('.top-info-section').addClass('has-slider');
    }

    // resize

    jQuery(window).on('resize', function() {
        initSubscribePlanSlider();
        initAdvantagesSlider();
        initServicesSlider();

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);

        if (jQuery(window).width() < 1271) {
            jQuery('.header-info .contacts-actions__email').prependTo(
                '.contacts-actions-mob'
            );
            jQuery('.header-info .social-menu').prependTo(
                '.contacts-actions-mob'
            );
            jQuery('.menu').prependTo('.header-info');
        } else {
            jQuery('.header-info .contacts-actions__email').appendTo(
                '.header-info .contacts-actions__left'
            );
            jQuery('.header-info .social-menu').appendTo(
                '.header-info .contacts-actions'
            );
            jQuery('.menu').insertAfter('.header__logo');
        }

        if (jQuery(window).outerWidth() < 1025) {
            jQuery('.footer-payments').appendTo('.footer-info');
        } else {
            jQuery('.footer-payments').appendTo('.footer__middle');

            var blockHeight = jQuery('.footer-info.info-menu').height();
            var paymentsHeight = jQuery('.footer-payments').height();
            var colHeight = 0;
            jQuery('.footer-info__col.widget_nav_menu').each(function(index) {
                if (index === 0) return;

                var height = jQuery(this).height();
                if (colHeight < height) colHeight = height;
            });

            if (blockHeight - colHeight > paymentsHeight) {
                jQuery('.footer-payments').addClass('up');
            } else {
                jQuery('.footer-payments').removeClass('up');
            }
        }

        if (jQuery(window).width() < 992) {
            jQuery('.licenses-arrows').appendTo('.licenses-slider-wrapper');
            jQuery('.reviews__buttons').appendTo('.reviews__content');
            jQuery('.faq__title .secondary-button').appendTo('.faq');
            jQuery('.heading-flex .see-all-link').each(function() {
                let thisParent = jQuery(this).closest('.section-inner');
                jQuery(this).appendTo(thisParent);
            });
            initSuggestionsSlider();
            jQuery('.header-info .contacts-actions__email').appendTo(
                '.header-info .contacts-actions'
            );
            jQuery('.header-top').appendTo('.header__inner');
            jQuery('.header-info .social-menu').appendTo(
                '.header-info .contacts-actions'
            );
        } else {
            jQuery('.licenses-arrows').appendTo('.licenses-nav');
            jQuery('.reviews__buttons').appendTo('.reviews__title');
            jQuery('.faq .secondary-button').appendTo('.faq__title');
            jQuery('.section-inner .see-all-link').each(function() {
                jQuery(this).appendTo(
                    jQuery(this)
                        .closest('.section-inner')
                        .find('.heading-flex')
                );
            });
            jQuery('.suggestions')
                .filter('.slick-initialized')
                .slick('unslick');
            jQuery('.header-top').prependTo('body');
        }

        if (jQuery(window).width() < 769) {
            jQuery('.footer__menu').insertAfter('.footer__top');
            jQuery('.footer-social').insertAfter(
                '.footer-info__col:nth-child(5)'
            );
        } else {
            jQuery('.footer__menu').appendTo('.footer__top');
            jQuery('.footer-social').insertAfter('.footer__copy');
        }

        if (jQuery(window).width() < 576) {
            jQuery('.header .contacts-content .social-menu').appendTo(
                '.contacts-actions-mob'
            );
        } else {
            let windowHeight = jQuery(window).height();
            jQuery('.main-slider__item').height(windowHeight);
        }
    });
    jQuery(window).trigger('resize');
});

function initSubscribePlanSlider() {
    jQuery('.subscribe-plan')
        .not('.slick-initialized')
        .slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 9999,
                    settings: 'unslick',
                },
                {
                    breakpoint: 1025,
                    settings: {
                        centerPadding: '25%',
                    },
                },
                {
                    breakpoint: 992,
                    settings: {
                        centerPadding: '20%',
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        centerPadding: '14%',
                    },
                },
                {
                    breakpoint: 576,
                    settings: {
                        centerPadding: '0',
                    },
                },
            ],
        });

    jQuery('.subscribe-plan').slick('slickGoTo', 1);
}
function initAdvantagesSlider() {
    jQuery('.advantages')
        .not('.slick-initialized')
        .slick({
            autoplay: true,
            autoplaySpeed: 1800,
            infinite: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            dots: false,
            arrows: false,
            responsive: [
                {
                    breakpoint: 9999,
                    settings: 'unslick',
                },
                {
                    breakpoint: 1201,
                    settings: {
                        slidesToShow: 4,
                    },
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 681,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 575,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });
}
function initServicesSlider() {
    jQuery('.services')
        .not('.slick-initialized')
        .slick({
            variableWidth: true,
            infinite: true,
            dots: true,
            arrows: false,
            responsive: [
                {
                    breakpoint: 9999,
                    settings: 'unslick',
                },
                {
                    breakpoint: 1201,
                    settings: {
                        slidesToShow: 4,
                        slideToScroll: 1,
                    },
                },
            ],
        });
}
function initSuggestionsSlider() {
    jQuery('.suggestions')
        .not('.slick-initialized')
        .slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            arrows: false,
        });
}
function intOfferSlider() {
    let offerSlider = jQuery('.offer-slider');

    offerSlider.slick({
        dots: false,
        infinite: true,
        arrows: true,
        speed: 600,
        cssEase: 'linear',
        centerMode: true,
        slidesToShow: 2.99,
        slidesToScroll: 1,
        nextArrow: jQuery('.offer-arrow-next'),
        prevArrow: jQuery('.offer-arrow-prev'),
    });

    if (offerSlider.slick('getSlick').slideCount === 3) {
        jQuery(offerSlider).addClass('three-slides');
    }
    if (offerSlider.slick('getSlick').slideCount === 2) {
        jQuery(offerSlider).addClass('two-slides');
    }
    checkSlickNoSlide();

    offerSlider.on('beforeChange', function(
        event,
        slick,
        currentSlide,
        nextSlide
    ) {
        let direction,
            slideCountZeroBased = slick.slideCount - 1;
        if (nextSlide == currentSlide) {
            direction = 'same';
        } else if (Math.abs(nextSlide - currentSlide) == 1) {
            direction = nextSlide - currentSlide > 0 ? 'right' : 'left';
        } else {
            direction = nextSlide - currentSlide > 0 ? 'left' : 'right';
        }
        if (direction == 'right') {
            jQuery(
                '.slick-cloned[data-slick-index="' +
                    (nextSlide + slideCountZeroBased + 1) +
                    '"]',
                offerSlider
            ).addClass('slick-current-clone-animate');
        }
        if (direction == 'left') {
            jQuery(
                '.slick-cloned[data-slick-index="' +
                    (nextSlide - slideCountZeroBased - 1) +
                    '"]',
                offerSlider
            ).addClass('slick-current-clone-animate');
        }
    });

    offerSlider.on('afterChange', function() {
        jQuery('.slick-current-clone-animate', offerSlider).removeClass(
            'slick-current-clone-animate'
        );
        jQuery('.slick-current-clone-animate', offerSlider).removeClass(
            'slick-current-clone-animate'
        );
    });

    function checkSlickNoSlide() {
        if (offerSlider.length) {
            let getSlick = offerSlider.slick('getSlick');
            if (getSlick.slideCount <= getSlick.options.slidesToShow) {
                offerSlider.addClass('slick-no-slide');
            } else {
                offerSlider.removeClass('slick-no-slide');
            }
        }
    }
}
function initServiceSlider() {
    let serviceSlider = jQuery('.service-slider');

    serviceSlider.slick({
        dots: false,
        infinite: true,
        arrows: true,
        speed: 1200,
        centerMode: true,
        centerPadding: '27.5%',
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: jQuery('.service-arrows .slider-next'),
        prevArrow: jQuery('.service-arrows .slider-prev'),
        responsive: [
            {
                breakpoint: 1271,
                settings: {
                    speed: 1000,
                    centerPadding: '25%',
                },
            },
        ],
    });

    serviceSlider.on('beforeChange', function(
        event,
        slick,
        currentSlide,
        nextSlide
    ) {
        let direction,
            slideCountZeroBased = slick.slideCount - 1;
        if (nextSlide == currentSlide) {
            direction = 'same';
        } else if (Math.abs(nextSlide - currentSlide) == 1) {
            direction = nextSlide - currentSlide > 0 ? 'right' : 'left';
        } else {
            direction = nextSlide - currentSlide > 0 ? 'left' : 'right';
        }
        if (direction == 'right') {
            jQuery(
                '.slick-cloned[data-slick-index="' +
                    (nextSlide + slideCountZeroBased + 1) +
                    '"]',
                serviceSlider
            ).addClass('slick-current-clone-animate');
        }
        if (direction == 'left') {
            jQuery(
                '.slick-cloned[data-slick-index="' +
                    (nextSlide - slideCountZeroBased - 1) +
                    '"]',
                serviceSlider
            ).addClass('slick-current-clone-animate');
        }
    });

    serviceSlider.on('afterChange', function() {
        jQuery('.slick-current-clone-animate', serviceSlider).removeClass(
            'slick-current-clone-animate'
        );
        jQuery('.slick-current-clone-animate', serviceSlider).removeClass(
            'slick-current-clone-animate'
        );
    });
}
function initLicensesSlider() {
    jQuery('.licenses-slider-for').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        fade: true,
        infinite: false,
        useTransform: true,
        speed: 400,
        cssEase: 'cubic-bezier(0.77, 0, 0.18, 1)',
        asNavFor: '.licenses-slider-nav',
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    infinite: true,
                },
            },
        ],
    });
    jQuery('.licenses-slider-nav').slick({
        asNavFor: '.licenses-slider-for',
        slidesToShow: 3,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        focusOnSelect: true,
        infinite: false,
        nextArrow: jQuery('.licenses-arrows .slider-next'),
        prevArrow: jQuery('.licenses-arrows .slider-prev'),
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: true,
                    centerPadding: '29%',
                },
            },
        ],
    });
}

function initClipboard() {
    new ClipboardJS('.copy-text');

    jQuery(document).on('click', '.copy-text', function(e) {
        e.preventDefault();
    });
}

function highlightSearchText() {
    var search = jQuery('.search-panel__form input[name="s"]').val();
    if (!search) return;

    new Mark('.search-panel--page .search-results__title').mark(search, {
        className: 'search-results__mark',
        element: 'span',
    });
}

function initAlarmAboutSlider() {
    jQuery('.alarm-about__slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        infinite: false,
        prevArrow: jQuery('.alarm-about__prev'),
        nextArrow: jQuery('.alarm-about__next'),
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
}

function initAlarmPlansSlider() {
    jQuery('.alarm-plans__items').slick({
        mobileFirst: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
        arrows: false,
        centerMode: true,
        centerPadding: '60px',
        responsive: [
            {
                breakpoint: 991,
                settings: 'unslick',
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 2,
                },
            },
        ],
    });
}

function initAlarmFormTabs() {
    jQuery('.alarm-form-tab').on('click', function() {
        var $self = jQuery(this);
        var target = $self.data('target');
        var $target = jQuery('#' + target);

        $self.siblings().removeClass('active');
        $self.addClass('active');

        $target.siblings().removeClass('active');
        $target.addClass('active');
    });
}

function initAboutDevSlider() {
    jQuery('.about-dev-slider').slick({
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false,
        infinite: false,
        prevArrow: jQuery('.about-dev-prev'),
        nextArrow: jQuery('.about-dev-next'),
        responsive: [
            {
                breakpoint: 575,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
}

function initAboutHistorySlider() {
    jQuery('.about-history-slider').slick({
        slidesToShow: 4,
        slidesToScroll: 1,
        dots: false,
        infinite: false,
        prevArrow: jQuery('.about-history-prev'),
        nextArrow: jQuery('.about-history-next'),
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });
}

function scrollTop() {
    var $btn = jQuery('#scroll-top');

    jQuery(window).on('scroll', function() {
        var offset = Math.round(jQuery(document).height() / 2);
        var scrollTop = jQuery(this).scrollTop();

        if (scrollTop > offset) {
            $btn.addClass('active');
        } else {
            $btn.removeClass('active');
        }
    });

    $btn.on('click', function(e) {
        e.preventDefault();

        jQuery('html, body').animate({ scrollTop: 0 }, 1200);
    });
}

function startCounterAnimation(el) {
    var $el = jQuery(el);
    if (!$el.hasClass('statistics__name')) return;

    var $item = $el.find('.statistics__count span');
    var number = parseInt($item.html().replace(/ /g, ''));
    var countUp = new CountUp($item[0], number, {
        separator: ' ',
        duration: 3,
    });

    countUp.start();
}

function addGuardTypeToContactForms() {
    jQuery(document).on('click', '[data-guard-type]', function() {
        var guardType = jQuery(this).data('guard-type');

        jQuery('input[name="guard-type"]').val(guardType);
    });
}
